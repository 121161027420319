<template>
  <v-row class="my-0 mx-auto">
    <v-col cols="12" class="pa-0 d-flex align-center justify-center">
      <v-card elevation="0" max-width="340" width="100%" rounded="lg" class="mx-auto my-4 px-md-0 px-2 mt-md-16 mt-7">
        <v-card width="72" height="72" tile flat class="secondary rounded-circle mx-auto d-flex align-center justify-center mb-7">
          <img src="@/assets/icon/lock.svg" height="32" alt="lock" />
        </v-card>
        <div class="text-center h2 mb-2">Create new password</div>
        <div class="h8 text--text mb-4">New password should contains at least one digit, one symbol and be at least 8 characters long</div>
        <v-form @submit.prevent="confirm" ref="form" lazy-validation>
          <div class="h10">New password</div>
          <v-text-field
            class="field44"
            v-model="password"
            :error-messages="passwordErrors"
            placeholder="Enter password"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            outlined
            dense
            required
            clearable
            clear-icon="mdi-close-circle"
            color="secondary"
            height="44"
          ></v-text-field>
          <v-btn depressed type="submit" block large color="primary">UPDATE</v-btn>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      password: '',
      show: false,
      error: [],
    };
  },
  methods: {
    async confirm() {
      this.error = [];
      const data = new Object();
      data.token = this.$route.query.t;
      data.password = this.password;
      await this.$store
        .dispatch('resetPassword', data)
        .then((res) => {
          sessionStorage.setItem('jwt_token', res.data.jwt_token);
          this.$router.push('/');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          if (this.error.find((item) => item == 'token__expired')) {
            this.$router.push('/expired');
          }
        });
    },
  },
  computed: {
    passwordErrors() {
      const errors = [];
      this.error.find((item) => item == 'password__required') && errors.push('Please enter your password');
      this.error.find((item) => item == 'password__invalid') && errors.push('Provided password is not valid');
      return errors;
    },
  },
};
</script>
